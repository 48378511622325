import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import Login from "@react-login-page/page11";
import LoginBannerBgImg from "../../login.png";
import { signIn } from "../../services/Auth";
import LoginPage, {
  Username,
  Password,
  Submit,
  Title,
  Logo,
  Banner,
} from "@react-login-page/page11";
import useAuthStore from "../../services/Auth";

const css = {
  "--login-bg": "#edeff3",
  "--login-color": "#3b4148",
  "--login-input": "#525D68",
  "--login-input-bg": "#d7dee5",
  "--login-input-hover": "#b6c3d1",
  "--login-input-placeholder": "#8e98a2",
  "--login-btn": "#fff",
  "--login-btn-bg": "#3A6D8C",
  "--login-btn-focus": "#3A6D8C",
  "--login-btn-hover": "#001F3F",
  "--login-btn-active": "#3A6D8C",
};

const Demo = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const setUser = useAuthStore((state) => state.setUser);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const { user, error } = await signIn(email, password);
    if (user) {
      const { id } = user.user;
      await setUser(id); // Set user in Zustand store

      // Redirect based on role
      const role = useAuthStore.getState().role;
      if (role === "IT Admin") {
        navigate("/dashboard");
      } else if (role === "User") {
        navigate("/tickets");
      } else {
        navigate("/");
      }
    } else {
      console.error("Login failed:", error.message);
      setError("Invalid email or password.");
    }
  };

  return (
    <Login style={{ height: "100vh", ...css }}>
      <Login.Banner>
        <img
          style={{
            height: "100vh",
            width: "100%",
            objectFit: "cover",
            borderTopLeftRadius: "5%",
            borderBottomLeftRadius: "5%",
            ...css,
          }}
          src={LoginBannerBgImg}
          alt="banner"
        />
      </Login.Banner>
      <form
        onSubmit={handleLogin}
        style={{ display: "flex", flexDirection: "column", margin: "auto" }}
      >
        <Logo visible={false}>Demo App</Logo>
        <Title>Login</Title>
        <Username
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Password
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Submit type="submit" onClick={handleLogin}>
          Login
        </Submit>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </form>
    </Login>
  );
};

export default Demo;
