import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  List,
  Tag,
  Spin,
  Typography,
  Space,
  message,
  Divider,
} from "antd";
import { PlusSquareOutlined, UnorderedListOutlined } from "@ant-design/icons";
import axios from "axios";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const { Title, Text } = Typography;

const UserHomePage = () => {
  const [isAskModalVisible, setAskModalVisible] = useState(false);
  const [isMyTicketsModalVisible, setMyTicketsModalVisible] = useState(false);
  const [isAdviceModalVisible, setIsAdviceModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myTicketsLoading, setMyTicketsLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [advices, setAdvices] = useState([]);
  const [aiLoading, setAiLoading] = useState(false);

  const handleAskTicket = () => {
    setAskModalVisible(true);
  };

  const handleMyTickets = () => {
    fetchMyTickets();
    setMyTicketsModalVisible(true);
  };

  const handleCancel = () => {
    setAskModalVisible(false);
    setMyTicketsModalVisible(false);
    setIsAdviceModalVisible(false);
    setSelectedTicket(null);
    setAdvices([]);
  };

  const fetchAdvices = async (ticketId) => {
    try {
      const { data, error } = await supabase
        .from("advice")
        .select("advice, assignee_name")
        .eq("ticket_id", ticketId);

      if (error) {
        console.error("Error fetching advices:", error.message);
        message.error("Failed to fetch advices. Please try again.");
        return [];
      }

      return data || [];
    } catch (error) {
      console.error("Error fetching advices:", error.message);
      return [];
    }
  };

  const handleViewTicketDetails = async (ticket) => {
    setSelectedTicket(ticket);
    const fetchedAdvices = await fetchAdvices(ticket.id);
    setAdvices(fetchedAdvices);
    setIsAdviceModalVisible(true);
  };
  const handleResolveTicket = async () => {
    try {
      const { error } = await supabase
        .from("ticket")
        .update({ state: "Resolved" })
        .eq("id", selectedTicket.id);

      if (error) {
        console.error("Error resolving ticket:", error.message);
        message.error("Failed to resolve the ticket. Please try again.");
        return;
      }

      setSelectedTicket({ ...selectedTicket, state: "Resolved" });
      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.id === selectedTicket.id
            ? { ...ticket, state: "Resolved" }
            : ticket
        )
      );

      message.success("Ticket marked as resolved!");
    } catch (error) {
      console.error("Error resolving ticket:", error.message);
      message.error("An unexpected error occurred. Please try again.");
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      setAiLoading(true);

      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError) {
        console.error("Error fetching session:", sessionError.message);
        message.error("Failed to get user session. Please try again.");
        setLoading(false);
        return;
      }

      const userId = session?.user?.id;

      if (!userId) {
        message.error("User not authenticated. Please log in.");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        "http://ticket-api-chi.vercel.app/create-ticket",
        {
          name: values.subject,
          userId,
          content: values.description,
        }
      );

      if (response.status === 201) {
        message.success(
          "Your inquiry has been submitted! AI is generating advice."
        );
        fetchMyTickets();
      } else {
        message.error("Failed to submit the ticket. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the ticket:", error.message);
      message.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
      setAiLoading(false);
      handleCancel();
    }
  };
  const handleGPTResponse = async (userResponse) => {
    try {
      setAiLoading(true);

      // Call the backend endpoint to process the response
      const response = await axios.post(
        "http://ticket-api-chi.vercel.app/respond-ticket",
        {
          ticketId: selectedTicket.id,
          userResponse,
        }
      );

      if (response.status === 200 && response.data?.advice) {
        // Save the new advice locally
        message.success("GPT has provided a new response!");

        // Fetch the updated advices list
        fetchAdvices(selectedTicket.id).then(setAdvices);
      } else {
        message.error("Failed to get a response from GPT. Please try again.");
      }
    } catch (error) {
      console.error("Error generating GPT response:", error.message);
      message.error("An unexpected error occurred. Please try again.");
    } finally {
      setAiLoading(false);
    }
  };

  const fetchMyTickets = async () => {
    try {
      setMyTicketsLoading(true);

      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError) {
        console.error("Error fetching session:", sessionError.message);
        message.error("Failed to get user session. Please try again.");
        return;
      }

      const userId = session?.user?.id;

      if (!userId) {
        message.error("User not authenticated. Please log in.");
        return;
      }

      const { data: ticketsData, error } = await supabase
        .from("ticket")
        .select("id, name, state, priority, content, category, creation_date")
        .eq("user_id", userId);

      if (error) {
        console.error("Error fetching tickets:", error.message);
        message.error("Failed to fetch your tickets. Please try again.");
        return;
      }

      setTickets(ticketsData || []);
    } catch (error) {
      console.error("Error fetching tickets:", error.message);
    } finally {
      setMyTicketsLoading(false);
    }
  };

  const handleAddAdvice = async (values) => {
    try {
      handleGPTResponse(values.response);
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError) {
        console.error("Error fetching session:", sessionError.message);
        message.error("Failed to get user session. Please try again.");
        return;
      }

      const userId = session?.user?.id;
      const firstName = session?.user?.user_metadata?.first_name || "User";

      const { error } = await supabase.from("advice").insert({
        ticket_id: selectedTicket.id,
        advice: values.response,
        assignee_name: firstName,
      });

      if (error) {
        console.error("Error adding advice:", error.message);
        message.error("Failed to add advice. Please try again.");
        return;
      }

      message.success("Advice added successfully!");
      fetchAdvices(selectedTicket.id).then(setAdvices);
    } catch (error) {
      console.error("Error adding advice:", error.message);
    }
  };

  return (
    <div
      style={{
        padding: "24px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Row gutter={[24, 24]} justify="center">
        <Col>
          <Card
            bordered={false}
            style={{
              width: "500px",
              height: "250px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              margin: "16px",
            }}
          >
            <PlusSquareOutlined
              style={{ fontSize: "64px", color: "#1890ff" }}
            />
            <h3>Ask for a ticket</h3>
            <Button
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={handleAskTicket}
              style={{ marginTop: "20px", width: "100%", borderRadius: "5px" }}
              size="large"
            >
              Ask for a ticket
            </Button>
          </Card>
        </Col>
        <Col>
          <Card
            bordered={false}
            style={{
              width: "500px",
              height: "250px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              margin: "16px",
            }}
          >
            <UnorderedListOutlined
              style={{ fontSize: "64px", color: "#52c41a" }}
            />
            <h3>My tickets</h3>
            <Button
              type="primary"
              icon={<UnorderedListOutlined />}
              onClick={handleMyTickets}
              style={{ marginTop: "20px", width: "100%", borderRadius: "5px" }}
              size="large"
            >
              My tickets
            </Button>
          </Card>
        </Col>
      </Row>

      <Modal
        title="Ask for a Ticket"
        visible={isAskModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item
            label="Ticket Subject"
            name="subject"
            rules={[{ required: true, message: "Please enter a subject!" }]}
          >
            <Input placeholder="Enter ticket subject" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please enter a description!" }]}
          >
            <Input.TextArea placeholder="Enter ticket description" />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form>
        {aiLoading && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spin />
            <p>AI is responding to your inquiry...</p>
          </div>
        )}
      </Modal>

      <Modal
        title="        My Tickets"
        visible={isMyTicketsModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {myTicketsLoading ? (
          <Spin style={{ display: "block", margin: "20px auto" }} />
        ) : (
          <List
            dataSource={tickets}
            renderItem={(ticket) => (
              <List.Item>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Title level={5}>{ticket.name}</Title>
                  <Text>
                    <strong>Category:</strong> {ticket.category}
                  </Text>
                  <Text>
                    <strong>Priority:</strong>{" "}
                    <Tag color={ticket.priority === "High" ? "red" : "blue"}>
                      {ticket.priority}
                    </Tag>
                  </Text>
                  <Text>
                    <strong>State:</strong>{" "}
                    <Tag color={ticket.state === "Open" ? "orange" : "green"}>
                      {ticket.state}
                    </Tag>
                  </Text>
                  <Text>
                    <strong>Content:</strong> {ticket.content}
                  </Text>
                  <Button
                    type="link"
                    onClick={() => handleViewTicketDetails(ticket)}
                  >
                    View Details
                  </Button>
                </Space>
              </List.Item>
            )}
          />
        )}
      </Modal>

      <Modal
        title={`Ticket Details - ${selectedTicket?.name}`}
        visible={isAdviceModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text>
            <strong>Category:</strong> {selectedTicket?.category}
          </Text>
          <Text>
            <strong>Priority:</strong>{" "}
            <Tag
              color={
                selectedTicket?.priority === "High"
                  ? "red"
                  : selectedTicket?.priority === "Medium"
                  ? "orange"
                  : "blue"
              }
            >
              {selectedTicket?.priority}
            </Tag>
          </Text>
          <Text>
            <strong>State:</strong>{" "}
            <Tag color={selectedTicket?.state === "Open" ? "orange" : "green"}>
              {selectedTicket?.state}
            </Tag>
          </Text>
          <Text>
            <strong>Content:</strong> {selectedTicket?.content}
          </Text>

          <Divider />
          <Title level={5}>Advices</Title>
          {advices.length > 0 ? (
            <List
              dataSource={advices}
              renderItem={(advice) => (
                <List.Item>
                  <Text>
                    <strong>{advice.assignee_name}:</strong> {advice.advice}
                  </Text>
                </List.Item>
              )}
            />
          ) : (
            <Text>No advices yet.</Text>
          )}
          {aiLoading && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Spin />
              <p>GPT is generating a new response...</p>
            </div>
          )}

          <Divider />
          {selectedTicket?.state === "Open" && (
            <>
              <Title level={5}>Add Your Response</Title>
              <Form layout="vertical" onFinish={handleAddAdvice}>
                <Form.Item
                  label="Your Response"
                  name="response"
                  rules={[
                    { required: true, message: "Please provide response!" },
                  ]}
                >
                  <Input.TextArea placeholder="Enter your response here" />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Submit Response
                </Button>
              </Form>

              <Divider />
              <Title level={5}>Is Your Issue Resolved?</Title>
              <Button
                type="primary"
                onClick={handleResolveTicket}
                style={{ marginRight: "10px" }}
              >
                Yes
              </Button>

              <Button
                type="default"
                onClick={() =>
                  message.info("Keep ticket open. Our Agent shall reply")
                }
              >
                No
              </Button>
            </>
          )}
        </Space>
      </Modal>
    </div>
  );
};

export default UserHomePage;
