import React, { useEffect, useState } from "react";
import {
  CloudServerOutlined,
  UserOutlined,
  PieChartOutlined,
  LogoutOutlined,
  HomeOutlined,
  SettingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Switch, Tooltip } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdminView, setIsAdminView] = useState(false); // Toggle between User and Admin views

  const selectedKey = () => {
    switch (location.pathname) {
      case "/":
        return "1";
      case "/tickets":
        return "2";
      case "/user":
        return "3";
      case "/home":
        return "home";
      case "/dashboard":
        return "dashboard";
      default:
        return "";
    }
  };

  const handleViewToggle = (checked) => {
    setIsAdminView(checked);
    navigate(checked ? "/dashboard" : "/home");
  };

  const renderMenuItems = () => {
    if (!isAdminView) {
      // User view menu
      return (
        <>
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to="/home">Home</Link>
          </Menu.Item>
        </>
      );
    } else {
      // Admin view menu
      return (
        <>
          <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<CloudServerOutlined />}>
            <Link to="/tickets">Tickets</Link>
          </Menu.Item>
        </>
      );
    }
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      style={{
        position: "fixed",
        height: "100vh",
        left: 0,
        top: 0,
        direction: "ltr",
      }}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div
        style={{
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#c7d3de",
          marginBottom: "16px",
        }}
      >
        <img src={`./logo.png`} alt="Logo" style={{ height: "40px" }} />
      </div>

      <Tooltip title="Toggle between User and Admin views (Demo Purpose)">
        <div
          style={{
            margin: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
          }}
        >
          <span>User</span>
          <Switch
            checked={isAdminView}
            onChange={handleViewToggle}
            checkedChildren="Admin"
            unCheckedChildren="User"
            style={{ marginLeft: "auto" }}
          />
        </div>
      </Tooltip>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKey()]}
        style={{ direction: "ltr" }}
      >
        {renderMenuItems()}
      </Menu>

      <Menu
        theme="dark"
        mode="inline"
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          direction: "ltr",
        }}
      >
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={() => {
            navigate("/");
          }}
        >
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Navbar;
