import React, { useState, useEffect } from "react";
import {
  Input,
  Table,
  Tag,
  Tooltip,
  Space,
  Select,
  message,
  Popconfirm,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { createClient } from "@supabase/supabase-js";
import TicketDetailsModal from "../../components/ticketModal/TicketDetailsModal";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const { Option } = Select;

const Customers = () => {
  const [searchText, setSearchText] = useState("");
  const [searchField, setSearchField] = useState("all");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState(null); // Selected ticket for the modal
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false); // Modal visibility

  const priorityInfo = {
    Low: "Low priority ticket.",
    Medium: "Medium priority ticket.",
    High: "High priority ticket.",
  };

  // Fetch tickets from Supabase
  const refreshTickets = async () => {
    try {
      setLoading(true);
      const { data: tickets, error } = await supabase
        .from("ticket")
        .select("*");

      if (error) {
        console.error("Error fetching tickets:", error.message);
        return;
      }

      setData(tickets);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshTickets();
    setIsAdmin(true); // Assuming the admin check logic is handled elsewhere
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const handleSearchFieldChange = (value) => {
    setSearchField(value);
  };

  const handleViewDetails = (record) => {
    setSelectedTicket(record); // Set selected ticket
    setIsDetailsModalVisible(true); // Show modal
  };

  const handleDelete = async (ticketId) => {
    try {
      const { error } = await supabase
        .from("ticket")
        .delete()
        .eq("id", ticketId);

      if (error) {
        console.error("Error deleting ticket:", error.message);
        message.error("Failed to delete ticket");
        return;
      }

      setData(data.filter((item) => item.id !== ticketId));
      message.success("Ticket deleted successfully");
    } catch (error) {
      console.error("Error deleting ticket:", error.message);
    }
  };

  const filteredData = data.filter((item) => {
    const searchValue = searchText.toLowerCase();

    switch (searchField) {
      case "id":
        return item.id.toString().toLowerCase().includes(searchValue);
      case "name":
        return item.name.toLowerCase().includes(searchValue);
      case "category":
        return item.category.toLowerCase().includes(searchValue);
      case "state":
        return item.state.toLowerCase().includes(searchValue);
      case "priority":
        return item.priority.toLowerCase().includes(searchValue);
      case "assignee":
        return item.assignee.toLowerCase().includes(searchValue);
      case "content":
        return item.content.toLowerCase().includes(searchValue);
      case "creation_date":
        return item.creation_date.toLowerCase().includes(searchValue);
      default:
        return Object.values(item)
          .join(" ")
          .toLowerCase()
          .includes(searchValue);
    }
  });

  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => {
        let color = "gray";
        if (priority === "Low") color = "blue";
        else if (priority === "Medium") color = "orange";
        else if (priority === "High") color = "red";
        return (
          <Tooltip title={priorityInfo[priority] || "Unknown Priority"}>
            <Tag color={color}>{priority}</Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state) => {
        let color =
          state === "Resolved" ? "green" : state === "Open" ? "red" : "gray";
        return <Tag color={color}>{state.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleViewDetails(record)}>
            <EyeOutlined /> View
          </a>
          <Popconfirm
            title="Are you sure you want to delete this ticket?"
            onConfirm={() => handleDelete(record.id)}
          >
            <a>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div style={{ padding: 20 }}>
        <Input
          placeholder="Search"
          onChange={handleSearch}
          value={searchText}
          style={{ width: 300, marginRight: 20 }}
        />
        <Select
          value={searchField}
          onChange={handleSearchFieldChange}
          style={{ width: 200, marginBottom: 20 }}
        >
          <Option value="all">All</Option>
          <Option value="id">ID</Option>
          <Option value="name">Name</Option>
          <Option value="category">Category</Option>
          <Option value="state">State</Option>
          <Option value="priority">Priority</Option>
          <Option value="assignee">Assignee</Option>
          <Option value="creation_date">Creation Date</Option>
          <Option value="content">Content</Option>
        </Select>
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 10 }}
        />
      </div>

      {/* Ticket Details Modal */}
      <TicketDetailsModal
        visible={isDetailsModalVisible}
        onClose={() => {
          setIsDetailsModalVisible(false);
          setSelectedTicket(null);
          refreshTickets(); // Refresh tickets after modal action
        }}
        ticket={selectedTicket}
        refreshTickets={refreshTickets}
      />
    </>
  );
};

export default Customers;
