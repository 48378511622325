import { createClient } from "@supabase/supabase-js";
import { create } from "zustand";

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

/**
 * Sign in a user with email and password
 * @param {string} email
 * @param {string} password
 * @returns {Promise<{error: object, user: object}>}
 */
export const signIn = async (email, password) => {
  const { data: user, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    console.error("Error signing in:", error.message);
    return { error, user: null };
  }

  console.log("User signed in:", user);
  return { error: null, user };
};

/**
 * Get the current user session
 * @returns {Promise<{error: object, user: object}>}
 */
export const getCurrentUser = async () => {
  const {
    data: { session },
    error,
  } = await supabase.auth.getSession();

  if (error) {
    console.error("Error fetching current user:", error.message);
    return { error, user: null };
  }

  return { error: null, user: session?.user };
};

const useAuthStore = create((set) => ({
  user: null, // User object
  role: null, // User role
  setUser: async (userId) => {
    try {
      // Fetch the user's role from the profiles table
      const { data, error } = await supabase
        .from("profiles")
        .select("role")
        .eq("id", userId)
        .single();

      if (error) {
        console.error("Error fetching user role:", error.message);
        throw new Error("Failed to fetch user role.");
      }
      console.log("User role:", data.role);
      // Set user and role in the store
      set(() => ({
        user: { id: userId, ...data },
        role: data.role || "User",
      }));
    } catch (err) {
      console.error("Error setting user in store:", err.message);
    }
  },
  clearUser: () =>
    set(() => ({
      user: null,
      role: null,
    })),
}));

export default useAuthStore;
