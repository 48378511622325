import React, { useState, useEffect } from "react";
import { Card, List, Tag, Table, Tooltip, Space, message } from "antd";
import { Bar } from "@ant-design/charts";
import { createClient } from "@supabase/supabase-js";
import TicketDetailsModal from "../../components/ticketModal/TicketDetailsModal";
import { EyeOutlined } from "@ant-design/icons";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const Dashboard = () => {
  const [risks, setRisks] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [unresolvedTickets, setUnresolvedTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [chartData, setChartData] = useState({ categories: [], states: [] });

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch risks
        const { data: risksData, error: risksError } = await supabase
          .from("risk")
          .select("*");

        if (risksError) {
          console.error("Error fetching risks:", risksError.message);
        } else {
          setRisks(risksData);
        }

        // Fetch tickets
        const { data: ticketsData, error: ticketsError } = await supabase
          .from("ticket")
          .select("*");

        if (ticketsError) {
          console.error("Error fetching tickets:", ticketsError.message);
        } else {
          setTickets(ticketsData);

          // Filter unresolved tickets
          setUnresolvedTickets(ticketsData.filter((t) => t.state === "Open"));

          // Prepare chart data
          const categoryCounts = ticketsData.reduce((acc, ticket) => {
            acc[ticket.category] = (acc[ticket.category] || 0) + 1;
            return acc;
          }, {});

          const stateCounts = ticketsData.reduce(
            (acc, ticket) => {
              acc[ticket.state] = (acc[ticket.state] || 0) + 1;
              return acc;
            },
            { Open: 0, Resolved: 0 }
          );

          setChartData({
            categories: Object.entries(categoryCounts).map(([key, value]) => ({
              type: key,
              value,
            })),
            states: Object.entries(stateCounts).map(([key, value]) => ({
              type: key,
              value,
            })),
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewDetails = (ticket) => {
    setSelectedTicket(ticket);
    setIsDetailsModalVisible(true);
  };

  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => {
        let color = "gray";
        if (priority === "Low") color = "blue";
        else if (priority === "Medium") color = "orange";
        else if (priority === "High") color = "red";
        return <Tag color={color}>{priority}</Tag>;
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state) => {
        let color =
          state === "Resolved" ? "green" : state === "Open" ? "red" : "gray";
        return <Tag color={color}>{state.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleViewDetails(record)}>
            <EyeOutlined /> View
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      {/* Risks Section */}
      <Card
        title="Potential Risks"
        style={{ marginBottom: 20 }}
        loading={loading}
      >
        <List
          dataSource={risks}
          renderItem={(risk) => (
            <List.Item>
              <Tag color="red" style={{ cursor: "pointer" }}>
                <Tooltip title={`Related to Ticket ID: ${risk.ticket_id}`}>
                  <span
                    onClick={() =>
                      handleViewDetails(
                        tickets.find((t) => t.id === risk.ticket_id)
                      )
                    }
                  >
                    {risk.content}
                  </span>
                </Tooltip>
              </Tag>
            </List.Item>
          )}
        />
      </Card>

      {/* Charts Section */}
      <Card title="Dashboard Metrics" style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "48%" }}>
            <h3>Ticket Categories</h3>
            <Bar
              data={chartData.categories}
              xField="type"
              yField="value"
              color="#3b5998"
              height={300}
              meta={{
                type: { alias: "Category" },
                value: { alias: "Count" },
              }}
              style={{ borderRadius: 10 }}
            />
          </div>
          <div style={{ width: "48%" }}>
            <h3>Open vs Resolved Tickets</h3>
            <Bar
              data={chartData.states}
              xField="type"
              yField="value"
              color={({ type }) => (type === "Open" ? "#ff4d4f" : "#73d13d")}
              height={300}
              meta={{
                type: { alias: "State" },
                value: { alias: "Count" },
              }}
              style={{ borderRadius: 10 }}
            />
          </div>
        </div>
      </Card>

      {/* Unresolved Tickets Section */}
      <Card title="Unresolved Tickets">
        <Table
          columns={columns}
          dataSource={unresolvedTickets}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 5 }}
        />
      </Card>

      {/* Ticket Details Modal */}
      <TicketDetailsModal
        visible={isDetailsModalVisible}
        onClose={() => setIsDetailsModalVisible(false)}
        ticket={selectedTicket}
      />
    </div>
  );
};

export default Dashboard;
