import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Tickets from "./pages/tickets";
import UserHome from "./pages/userHome";
import Navbar from "./components/navbar";
import PageHeader from "./components/PageHeader";
import Login from "./pages/login";
import { Layout, theme } from "antd";
import Dashboard from "./pages/dashboard";
const { Header, Content, Footer } = Layout;

const App = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Router>
      <Routes>
        {/* Route for Login */}
        <Route path="/" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="*"
          element={
            <Layout style={{ minHeight: "100vh" }}>
              <Navbar />
              <Layout style={{ marginLeft: 200 }}>
                <PageHeader />
                <Content style={{ margin: "24px 16px 0", flex: "1 0 auto" }}>
                  <div
                    style={{
                      minHeight: 360,
                      padding: 24,
                      background: colorBgContainer,
                      borderRadius: borderRadiusLG,
                    }}
                  >
                    <Routes>
                      <Route path="/tickets" element={<Tickets />} />
                      <Route path="/home" element={<UserHome />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                    </Routes>
                  </div>
                </Content>
                <Footer style={{ textAlign: "center", flexShrink: 0 }}>
                  ©{new Date().getFullYear()} Created by Waseet Team :)
                </Footer>
              </Layout>
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
