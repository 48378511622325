import React from "react";
import { useLocation } from "react-router-dom";
import {
  PieChartOutlined,
  UserOutlined,
  CloudServerOutlined,
  FileTextOutlined,
  HomeOutlined,
} from "@ant-design/icons"; // Updated to match the Navbar icons

const PageHeader = () => {
  const location = useLocation();

  const getPageTitleAndIcon = (path) => {
    switch (true) {
      case path === "/":
        return { title: "Dashboard", icon: <PieChartOutlined /> }; // Matches Navbar
      case path === "/tickets":
        return { title: "Tickets", icon: <CloudServerOutlined /> }; // Matches Navbar
        case path === "/home":
          return { title: "Home", icon: <HomeOutlined /> }; // Matches Navbar
      default:
        return { title: "Page", icon: null };
    }
  };

  const { title, icon } = getPageTitleAndIcon(location.pathname);

  return (
    <div
      style={{
        padding: "16px",
        background: "#fff",
        marginBottom: "24px",
        borderBottom: "1px solid #e8e8e8",
        fontSize: "24px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
      }}
    >
      {icon && <span style={{ marginRight: "8px" }}>{icon}</span>}
      {title}
    </div>
  );
};

export default PageHeader;