import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Avatar,
  Typography,
  Divider,
  Tag,
  Space,
  Tooltip,
  message,
  Input,
  Form,
  List,
} from "antd";
import { UserOutlined, RobotOutlined } from "@ant-design/icons";
import { createClient } from "@supabase/supabase-js";
import useAuthStore from "../../services/Auth"; // Import useAuthStore for user information

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const { Title, Text } = Typography;

const TicketDetailsModal = ({ visible, ticket, onClose, refreshTickets }) => {
  const [advices, setAdvices] = useState([]); // Store multiple advices
  const [loading, setLoading] = useState(false);
  const [adminResponse, setAdminResponse] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useAuthStore((state) => state.user); // Get user from useAuthStore

  useEffect(() => {
    if (ticket) {
      fetchAdvices();
    }
  }, [ticket]);

  const fetchAdvices = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from("advice")
        .select("advice, assignee_name")
        .eq("ticket_id", ticket.id);

      if (error) {
        console.error("Error fetching advices:", error.message);
        setAdvices([]);
        return;
      }
      setAdvices(data || []);
    } catch (error) {
      console.error("Error fetching advices:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseTicket = async () => {
    try {
      const resolutionTime = new Date() - new Date(ticket.creation_date);

      const { error } = await supabase
        .from("ticket")
        .update({
          state: "Resolved",
          time_of_resolution: `${Math.floor(
            resolutionTime / (1000 * 60)
          )} minutes`,
        })
        .eq("id", ticket.id);

      if (error) {
        console.error("Error closing ticket:", error.message);
        message.error("Failed to close the ticket.");
        return;
      }

      message.success("Ticket closed successfully!");
      refreshTickets();
      onClose();
    } catch (error) {
      console.error("Error closing ticket:", error.message);
    }
  };

  const handleReopenTicket = async () => {
    try {
      const { error } = await supabase
        .from("ticket")
        .update({
          state: "Open",
          time_of_resolution: null,
        })
        .eq("id", ticket.id);

      if (error) {
        console.error("Error reopening ticket:", error.message);
        message.error("Failed to reopen the ticket.");
        return;
      }

      message.success("Ticket reopened successfully!");
      refreshTickets();
      onClose();
    } catch (error) {
      console.error("Error reopening ticket:", error.message);
    }
  };

  const handleAddAdvice = async () => {
    try {
      setIsSubmitting(true);

      const { error } = await supabase
        .from("advice")
        .insert([
          {
            ticket_id: ticket.id,
            advice: adminResponse,
            assignee_name: "IT Agent",
          },
        ]);

      if (error) {
        console.error("Error adding advice:", error.message);
        message.error("Failed to add advice.");
        return;
      }

      message.success("Advice added successfully!");
      setAdminResponse("");
      fetchAdvices();
      refreshTickets();
    } catch (error) {
      console.error("Error adding advice:", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Avatar
            icon={<UserOutlined />}
            style={{ backgroundColor: "#87d068" }}
          />
          <Title level={5} style={{ margin: 0 }}>
            {ticket?.name || "Ticket Details"}
          </Title>
        </div>
      }
      visible={visible}
      onCancel={onClose}
      footer={
        <Space>
          {ticket?.state === "Resolved" ? (
            <Button onClick={handleReopenTicket} type="default">
              Reopen Ticket
            </Button>
          ) : (
            <Button onClick={handleCloseTicket} type="primary">
              Close Ticket
            </Button>
          )}
          <Button onClick={onClose}>Cancel</Button>
        </Space>
      }
      width={700}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* Ticket Metadata */}
        <div>
          <Title level={5}>Ticket Information</Title>
          <Text>
            <strong>Category:</strong> {ticket?.category || "N/A"}
          </Text>
          <br />
          <Text>
            <strong>Priority:</strong>{" "}
            <Tag color={ticket?.priority === "High" ? "red" : "blue"}>
              {ticket?.priority}
            </Tag>
          </Text>
          <br />
          <Text>
            <strong>State:</strong>{" "}
            <Tag color={ticket?.state === "Open" ? "orange" : "green"}>
              {ticket?.state}
            </Tag>
          </Text>
          <br />
          <Text>
            <strong>Created At:</strong>{" "}
            {new Date(ticket?.creation_date).toLocaleString()}
          </Text>
        </div>

        <Divider />

        {/* Ticket Content */}
        <div>
          <Title level={5}>Content</Title>
          <Text>{ticket?.content || "No content provided."}</Text>
        </div>

        <Divider />

        {/* Advice Section */}
        <div>
          <Title level={5}>Advices</Title>
          <List
            dataSource={advices}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar icon={item.assignee_name ? <UserOutlined /> : <RobotOutlined />} />
                  }
                  title={item.assignee_name || "GPT"}
                  description={item.advice}
                />
              </List.Item>
            )}
          />
        </div>

        {/* Add Admin Response */}
        {ticket?.state === "Open" && (
          <>
            <Divider />
            <Form
              onFinish={handleAddAdvice}
              style={{ display: "flex", gap: "12px" }}
            >
              <Input.TextArea
                rows={2}
                placeholder="Add advice for this ticket"
                value={adminResponse}
                onChange={(e) => setAdminResponse(e.target.value)}
                style={{ flex: 1 }}
              />
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                disabled={!adminResponse.trim()}
              >
                Add Advice
              </Button>
            </Form>
          </>
        )}
      </Space>
    </Modal>
  );
};

export default TicketDetailsModal;
